@header3: header3;

.ant-dropdown-menu-submenu-expand-icon {
  top: calc(50% - 11px);

  .ant-dropdown-menu-submenu-arrow-icon {
    font-weight: bold;
    font-size: 14px;
  }
}

.@{header3} {
  background: #fff;
  width: 100%;
  z-index: 101;
  box-shadow: 0 4px 6px fade(#000, 5);
  top: 0;
  position: fixed;
  color: @text-color;

  .home-page {
    margin-right: 20px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  &-logo {
    display: inline-block;
    position: relative;
    line-height: 66px;
    padding: 0 16px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-color: #1DA57A;

    & img {
      vertical-align: middle;
      display: inline-block;
    }

    & a {
      display: block;
    }
  }

  &-dropdown-btn {
    font-size: 18px;
    font-weight: 600;
    margin: 0 20px;

    & span {
      stroke: @text-color
    }
  }

  &-dropdown-btn:hover {
    color: @primary-color-active;

    & span {
      stroke: @primary-color-active;
    }
  }

  &-menu-item-title {
    color: @text-color;
    font-size: 16px;
    // font-weight: 600;
    line-height: 26px;
  }

  &-submenu-item-title {
    color: @text-color;
    font-size: 14px;
    // font-weight: 600;
    line-height: 22px;
  }

  &-menu-item-desc {
    color: #555;
    font-size: 15px;
    line-height: 26px;
  }

  &-menu {
    float: right;
    .ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu {
      line-height: 62px;
      height: 64px;
      background: transparent;

      a {
        display: block;
      }
    }
  }

  &-item {
    &-block {
      padding: 0 8px;

      >* {
        display: inline-block;
      }
    }
  }

  &-item,
  &-item-child,
  &-menu {

    .ant-menu-sub .ant-menu-item,
    .ant-menu-inline .ant-menu-item {
      height: auto;
      line-height: 1.5;
    }

    .item {
      &-sub-item {
        display: block;
        padding: 8px 24px;
      }

      &-image {
        float: left;
        margin-right: 16px;
        margin-top: 4px;
        position: relative;
        z-index: 1;
      }

      &-title {
        font-size: 14px;
        color: @text-color;
        margin-left: 46px;
      }

      &-content {
        font-size: 12px;
        color: fade(@text-color, 75);
        margin-left: 46px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{header3} {
    &-logo {
      z-index: 101;
    }

    & .home-page {
      padding-right: 10px;
    }

    &-menu {
      height: auto;
      float: inherit;
      position: relative;
      left: -10px;
      width: ~"calc(100% + 10px)";
      opacity: 0;
      transition: opacity .3s @ease-in-out;
      background: #fff;
      font-weight: 500;

      & li {
        padding: 0 24px;

        &.ant-menu-submenu {
          padding: 0;
        }
      }
      .item {
        &-sub-item {
          padding: 8px 0;
        }
      }
    }

    &-mobile-menu {
      width: 25px;
      height: 21px;
      cursor: pointer;

      em {
        display: block;
        width: 100%;
        height: 3px;
        background: @template-nav-bg-color;
        margin-top: 6px;
        transition: transform .3s @ease-in-out, opacity .3s @ease-in-out;
      }

      :first-child {
        margin-top: 0;
      }
    }

    .ant-menu {
      height: auto;
      overflow: hidden;

      .ant-menu-item-selected {
        border: none;
      }
    }

    & .open {
      height: auto;

      .@{header3}-mobile-menu {
        em {
          &:nth-child(1) {
            transform: translateY(9.6px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: translateY(-8.5px) rotate(-45deg);
          }
        }
      }

      >.@{header3}-menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &-item-block {
      height: 50px;
      line-height: 50px;
    }
  }
}
