@import "~antd/lib/style/themes/default.less";

@primary-color-active: #76b900;
@line-color: #e9e9e9;
@text-color: #333;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: .45s;

@title-font-size: 28px;
@title-font-weight: 700;

h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25em;
  color: #000;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.25em;
  color: #000;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25em;
  color: #000;
}

h4 {
  font-size: 18px;
  line-height: 1.6em;
  color: #000;
}

p {
  font-size: 15px;
  color: #1a1a1a;
  line-height: 1.67em;
}

.anchor {
  display: block;
  position: relative;
  top: -62px;
  visibility: hidden;
}

/* 详细页图片或框框的样式;
*/
.page-shadow() {
  box-shadow: 0 5px 8px @shadow-color;
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid @line-color;
  transform: translateY(0);
  transition: transform .3s @ease-out, box-shadow .3s @ease-out;
  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}
