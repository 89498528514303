@content0: content0;

.@{content0}-wrapper {
  overflow: hidden;
  background: #fafafa;

  .@{content0} {
    height: 100%;
    padding: 100px 24px;

    >.title-wrapper {
      font-size: @title-font-size;
      font-weight: @title-font-weight;
      margin: 0 auto 48px;
    }

    &-block {
      padding: 0 10px 40px 10px;
      display: inline-block;
      text-align: center;

      img {
        width: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 100px;
        height: 100px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    min-height: 880px;
  }
}
