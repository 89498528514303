@banner0: banner0;
.@{banner0} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 55vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url('../banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  & &-text-wrapper {
    display: flex;
    padding-left: 10%;
    height: 60vh;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: linear-gradient(90deg, #000000ff, #ffffff00);
    max-width: 750px;
  }
  & &-title {
    min-height: 60px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  & &-content {
    margin-bottom: 20px;
    font-size: 24px;
    word-wrap: break-word;
    text-align: left;
    min-height: 24px;
  }
  & &-button {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    font-size: 16px;
    height: 40px;
    margin-bottom: 80px;
    transition: background .45s @ease-out, box-shadow .45s @ease-out;
    text-shadow: #000 2px 0 10px;
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
    }
    &:focus {
      color: #fff;
      border-color: #fff;
    }
  }

  & &-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & img {
      width: 100%;
      height: 60vh;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{banner0} {
    background-attachment: inherit;
    & &-text-wrapper {
      padding-left: 0%;
      width: 100%;
    }
    & &-title {
      padding: 0 12px;
      width: 100%;
      text-align: center;
    }

    & &-content {
      width: 100%;
      padding: 0 12px;
      text-align: center;
    }
  }
}
