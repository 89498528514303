@uefi: uefi;

.@{uefi}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{uefi} {
    height: 100%;
    padding: 100px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    >.title-wrapper {
      font-size: @title-font-size;
      font-weight: @title-font-weight;
      margin: 0 auto 48px;
    }

    &-subtitle {
      margin-top: 40px;
      max-width: 1000px;
    }

    &-block {
      padding: 0 2%;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        max-width: 750px;
      }

      &-item {
        text-align: left;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 100px;
        height: 100px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 24px;
      }

      &-button {
        background: @primary-color-active;
        font-size: 26px;
        font-weight: 700;
        margin-top: 40px;
        border-radius: 5px;
        padding: 0 30px;
        color: #fff;
        box-shadow: 0 0 0 transparent;
        font-size: 16px;
        height: 45px;
        transition: background .45s @ease-out, box-shadow .45s @ease-out;
        border: none;
        &:hover {
          color: #fff;
          box-shadow: 0 0 10px rgba(50, 250, 255, 0.85);
          scale: 1.02;
        }
        &:focus {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{cwp}-wrapper {
    min-height: 880px;
  }
}
