@content4: content4;
.@{content4}-wrapper {
  background: #fafafa;
  .@{content4} {
    height: 100%;
    padding: 100px 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-media {
      overflow: hidden;
      max-width: 900px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content4}-wrapper {
    .@{content4} {
      overflow: hidden;
      width: 90%;
      margin: auto;
      &-media {
        top: 15%;
      }
    }
  }
}
